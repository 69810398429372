import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SuccessStoryCard from "../components/success-story-card"
import ActionForm from "../components/action-form"

const SuccessStories = ({ data }) => (
  <Layout>
    <SEO
      title="Success Stories | Celantur"
      description="Read how companies successfully use Celantur to protect privacy on images and videos."
    />
    <section className="pt-8">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center mb-8">
            <h1 className="display-3 font-weight-bold">Success Stories</h1>
            <p className="text-muted">
              Discover why many of the world’s leading companies are choosing
              Celantur to help them solve privacy challenges.
            </p>
          </div>
        </div>
        <div className="row">
          <SuccessStoryCard
            title={
              <>
                <mark>NavVis</mark> provides image anonymization on a global scale
              </>
            }
            link="/success-story-navvis/"
            image={data.navvisImage.childImageSharp.fluid}
          />
          <SuccessStoryCard
            title={
              <>
                <mark>Horus</mark> streamlines blurring and GDPR compliance for Mobile Mapping applications
              </>
            }
            link="/success-story-horus/"
            image={data.horusPano.childImageSharp.fluid}
          />
        </div>
        <div className="row">
        <SuccessStoryCard
            title={
              <>
                <mark>Fortune 500 tech company</mark> develops L4 self-driving vehicle technology in a GDPR-compliant manner
              </>
            }
            link="/success-story-fortune-500-self-driving/"
            image={data.traffic.childImageSharp.fluid}
          />
          <SuccessStoryCard
            title={
              <>
                <mark>American University of Beirut</mark> protects citizens'
                privacy while assessing damages after the Beirut Explosion
              </>
            }
            link="/success-story-american-university-beirut/"
            image={data.beirutHighway.childImageSharp.fluid}
          />
        </div>
        <div className="row">
        <SuccessStoryCard
            title={
              <>
                <mark>orbis360</mark> maps Switzerland while protecting privacy
              </>
            }
            link="/success-story-orbis360/"
            image={data.orbisImage.childImageSharp.fluid}
          />
          <SuccessStoryCard
            title={
              <>
                <mark>qapture</mark> creates GDPR-compliant digital twins
              </>
            }
            link="/success-story-qapture/"
            image={data.qaptureImage.childImageSharp.fluid}
          />
        </div>
        <div className="row">
        <SuccessStoryCard
            title={
              <>
                <mark>greehill</mark> provides Green Asset Management without
                privacy concerns
              </>
            }
            link="/success-story-greehill/"
            image={data.greehillPointcloud.childImageSharp.fluid}
          />
        <SuccessStoryCard
            title={
              <>
                <mark>MiraCAD</mark> anonymizes ultra high resolution images
              </>
            }
            link="/success-story-miracad/"
            image={data.miracadImage.childImageSharp.fluid}
          />
        </div>
        <div className="row">
          <SuccessStoryCard
            title={
              <>              
                <mark>Looom</mark> uses Celantur Cloud API for GDPR-compliant AR/VR applications
              </>
            }
            link="/success-story-looom/"
            image={data.looomImage.childImageSharp.fluid}
          />
        <SuccessStoryCard
            title={
              <>              
                <mark>PLACE</mark> anonymizes street-level images of settlements in Nairobi, Kenya
              </>
            }
            link="/success-story-place/"
            image={data.placeImage.childImageSharp.fluid}
          />
        </div>
      </div>
    </section>

    <section className="py-8">
      <div className="container">
        <ActionForm source="success-stories-page" />
      </div>
    </section>
  </Layout>
)

export default SuccessStories

export const logo = graphql`
  fragment imageSuccessStory on File {
    childImageSharp {
      fluid(maxWidth: 640) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    beirutHighway: file(relativePath: { eq: "beirut-highway.jpg" }) {
      ...imageSuccessStory
    }
    greehillPointcloud: file(relativePath: { eq: "greehill-pointcloud.png" }) {
      ...imageSuccessStory
    }
    traffic: file(relativePath: { eq: "traffic.jpg" }) {
      ...imageSuccessStory
    }
    placeImage: file(relativePath: { eq: "place-sample-image.JPG" }) {
      ...imageSuccessStory
    }
    orbisImage: file(relativePath: { eq: "sample-image-orbis360.jpg" }) {
      ...imageSuccessStory
    }
    qaptureImage: file(relativePath: { eq: "qapture-success-story.png" }) {
      ...imageSuccessStory
    }
    miracadImage: file(relativePath: { eq: "datacenter.jpg" }) {
      ...imageSuccessStory
    }
    horusPano: file(relativePath: { eq: "horus-pano.jpg" }) {
      ...imageSuccessStory
    }
    navvisImage: file(relativePath: { eq: "navvis-sample-1.jpg" }) {
      ...imageSuccessStory
    }
    looomImage: file(relativePath: { eq: "looom-pano-garage.jpg" }) {
      ...imageSuccessStory
    }
  }
`
